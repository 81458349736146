import { GatsbyProps } from 'models/gatsby-props';
import { createContext, useEffect, useState } from 'react';
import { UserCheck } from 'components/user-check';
import { isSafari } from 'react-device-detect';

interface State {
    loaded: boolean;
    authenticated: boolean;
    canRedirect: boolean;
}

const initialState: State = {
    loaded: process.env.GATSBY_ENVIRONMENT === 'development' || isSafari,
    authenticated: false,
    canRedirect: true
};

export const AppStateContext = createContext<State | null>(null);

const AppContextProvider = ({ children }: GatsbyProps) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        const elumityAppUrl = process.env.GATSBY_ELUMITY_APP_URL || '';
        const referrer = document.referrer || '';

        const canRedirect = !referrer || !elumityAppUrl || !referrer.includes(elumityAppUrl);
        setState(prevState => ({ ...prevState, canRedirect: canRedirect }));
    }, []);

    return (
        <AppStateContext.Provider value={state}>
            {process.env.GATSBY_ENVIRONMENT === 'production' && !isSafari ? (
                <UserCheck
                    handleLoggedInUser={() =>
                        setState(prevState => ({ ...prevState, loaded: true, authenticated: true }))
                    }
                    handleNotLoggedInUser={() =>
                        setState(prevState => ({ ...prevState, loaded: true, authenticated: false }))
                    }
                />
            ) : null}
            {children}
        </AppStateContext.Provider>
    );
};

export default AppContextProvider;
