exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-copyright-tsx": () => import("./../../../src/pages/copyright.tsx" /* webpackChunkName: "component---src-pages-copyright-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-advanced-features-browser-extension-browser-extension-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/advanced-features/browser-extension/browser-extension.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-advanced-features-browser-extension-browser-extension-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-advanced-features-mobile-app-mobile-app-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/advanced-features/mobile-app/mobile-app.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-advanced-features-mobile-app-mobile-app-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-advanced-features-user-settings-security-backup-user-settings-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/advanced-features/user-settings-security-backup/user-settings.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-advanced-features-user-settings-security-backup-user-settings-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-getting-started-creating-an-account-creating-an-account-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/getting-started/creating-an-account/creating-an-account.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-getting-started-creating-an-account-creating-an-account-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-getting-started-introduction-to-elumity-introduction-to-elumity-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/getting-started/introduction-to-elumity/introduction-to-elumity.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-getting-started-introduction-to-elumity-introduction-to-elumity-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-getting-started-navigating-the-user-interface-navigating-the-ui-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/getting-started/navigating-the-user-interface/navigating-the-ui.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-getting-started-navigating-the-user-interface-navigating-the-ui-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-knowledge-cards-connecting-information-connecting-information-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/knowledge-cards/connecting-information/connecting-information.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-knowledge-cards-connecting-information-connecting-information-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-knowledge-cards-creating-cards-creating-cards-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/knowledge-cards/creating-cards/creating-cards.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-knowledge-cards-creating-cards-creating-cards-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-knowledge-cards-publishing-and-finding-cards-publishing-and-finding-cards-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/knowledge-cards/publishing-and-finding-cards/publishing-and-finding-cards.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-knowledge-cards-publishing-and-finding-cards-publishing-and-finding-cards-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-library-creating-and-managing-folders-creating-folders-folder-tree-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/library/creating-and-managing-folders/creating-folders-folder-tree.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-library-creating-and-managing-folders-creating-folders-folder-tree-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-library-uploading-documents-uploading-documents-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/library/uploading-documents/uploading-documents.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-library-uploading-documents-uploading-documents-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-library-using-the-library-using-library-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/library/using-the-library/using-library.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-library-using-the-library-using-library-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-other-faq-faq-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/other/faq/faq.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-other-faq-faq-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-other-glossary-glossary-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/other/glossary/glossary.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-other-glossary-glossary-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-quiz-and-learning-creating-quizzes-create-quiz-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/quiz-and-learning/creating-quizzes/create-quiz.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-quiz-and-learning-creating-quizzes-create-quiz-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-quiz-and-learning-editing-and-repeating-editing-and-repeating-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/quiz-and-learning/editing-and-repeating/editing-and-repeating.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-quiz-and-learning-editing-and-repeating-editing-and-repeating-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-references-import-from-pubmed-import-from-pubmed-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/references/import-from-pubmed/import-from-pubmed.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-references-import-from-pubmed-import-from-pubmed-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-references-using-references-using-references-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/references/using-references/using-references.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-references-using-references-using-references-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-writing-citing-sources-citing-sources-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/writing/citing-sources/citing-sources.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-writing-citing-sources-citing-sources-mdx" */),
  "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-writing-writing-tools-writing-tools-mdx": () => import("./../../../src/templates/help-hub-entry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/resources/help-hub/writing/writing-tools/writing-tools.mdx" /* webpackChunkName: "component---src-templates-help-hub-entry-tsx-content-file-path-resources-help-hub-writing-writing-tools-writing-tools-mdx" */)
}

