import { GatsbyProps } from 'models/gatsby-props';
import { useRef, useEffect, MutableRefObject } from 'react';

import { hiddenElumityAppIframe } from './user-check.module.scss';

const USER_STATE_KEY = 'user-state';

interface Props extends GatsbyProps {
    handleLoggedInUser: () => void;
    handleNotLoggedInUser: () => void;
}

export const UserCheck = ({ handleLoggedInUser, handleNotLoggedInUser }: Props) => {
    const elumityAppIframe: MutableRefObject<HTMLIFrameElement | null> = useRef(null);
    const messageIntervalId: MutableRefObject<NodeJS.Timeout | undefined> = useRef(undefined);

    const handleMessageEvent = (event: MessageEvent) => {
        if (event.origin !== process.env.GATSBY_ELUMITY_APP_URL) {
            return;
        }
        if (event.data) {
            sessionStorage.setItem(USER_STATE_KEY, JSON.stringify({ authenticated: true }));
            handleLoggedInUser();
        } else {
            sessionStorage.setItem(USER_STATE_KEY, JSON.stringify({ authenticated: false }));
            handleNotLoggedInUser();
        }
        window.removeEventListener('message', handleMessageEvent);
        clearInterval(messageIntervalId.current);
    };

    const sendPostMessage = () => {
        if (elumityAppIframe?.current?.contentWindow && process.env.GATSBY_ELUMITY_APP_URL) {
            elumityAppIframe.current.contentWindow.postMessage('', process.env.GATSBY_ELUMITY_APP_URL);
        }
    };

    const initIframeMessagingInterval = () => {
        messageIntervalId.current = setInterval(sendPostMessage, 100);
    };

    useEffect(() => {
        window.addEventListener('message', handleMessageEvent);

        initIframeMessagingInterval();

        return () => {
            clearInterval(messageIntervalId.current);
            window.removeEventListener('message', handleMessageEvent);
        };
    }, []);

    return (
        <iframe
            ref={elumityAppIframe}
            src={`${process.env.GATSBY_ELUMITY_APP_URL}`}
            title="Elumity App"
            className={hiddenElumityAppIframe}></iframe>
    );
};
